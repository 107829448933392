<template>
  <!-- id : contents : (S) -->
  <div id='contents' >
    <div class="sub-visual-wrap" :class="returnClass">
      <div class="inner-base-box">
        <h2 class="h2">{{ menuName }}</h2>
        <ol>
          <li style="background: none;">홈</li>
          <li>{{ menuName }}</li>
          <li>{{ currentTabMenu.menuName }}</li>
        </ol>
      </div>
    </div>

    <!-- class : content-wrap : (S) -->
    <section class="content-wrap st-center">
      <div class="inner-base-box mb-pd-none">
        <div class="tab-menu-box">
          <a href="javascript:;" class="btn-menu " :class="{open : this.$store.state.tapOpen === true}" @click="openTab()">탭열기버튼</a>
          <span class="t-txt">MENU</span>
        </div>
        <div class="secPageTabBox " :class="{open : this.$store.state.tapOpen === true}">
          <div class="btn-go-back mb-only"><a href="#" @click.prevent="goback">이전페이지</a></div>
          <div class="sub-tab-menu-box mb40 "  style="height:auto;" :class="returnClass">
            <a href="javascript:;" v-for="(tab) in tabs" :class="{on:currentTabMenu.boardId === tab.boardId}" @click="onClickTab(tab)" :key="tab.boardId">{{ tab.menuName }}</a>
          </div>
          <div class="sub-tab-mb-title">
            <a href="javascript:;" v-for="(tab) in tabs" :class="{on:currentTabMenu.menuName==tab.menuName}" @click="onClickTabMobile()" :key="tab.boardId">{{ tab.menuName }} <i class="arrow"></i> </a>
          </div>
          <div class="sub-tab-list-box" :class="{on:this.tablistOpen === true}">
            <a href="javascript:;" v-for="(tab, index) in tabs" :class="{on:currentTabMenu.menuName==tab.menuName}" @click="onClickTab(tab, index)" :key="tab.boardId">{{ tab.menuName }}</a>
          </div>
        </div>
        <!-- division-notice-box (S) --> 
        <div class="division-notice-box" v-if="group != null && componentStatus=='list'" :class="{open : this.$store.state.tapOpen === true}">
          <div class="exp-box">
            <div class="tit-box">
              <div class="tit">{{ currentTabMenu.menuName }}</div>
              <p><span>위원장</span> <em>{{ this.chairman!=''?this.chairman:'-' }}</em></p>
              <p><span>간사</span> <em>{{ this.secretary!=''?this.secretary:'-' }}</em></p>
            </div>
            <div class="txt-box" v-html="group.groupContents"></div>
          </div>
          <div class="btn-box"><a href="javascript:;" class="" @click="onClickUser(null, 'list')">{{ menuName.includes('분과')?'분과':'동호회' }}인원</a></div>
        </div>  
        <!-- division-notice-box (E) -->
      
      </div>
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="inner-base-box mb-pd-none">
          <!-- board-tab-box (S) -->
          <div class="board-tab-box"  style="justify-content: left">
            <div class="tit-tabs-box" v-if="this.boardId=='567'||this.boardId=='569'">
              <div v-for="category in board.boardCategories" :key="category.categoryId" style="margin-right: 30px;">
                <a href="javascript:;" :class="{on:category.categoryId==subTabCategory}" @click="onClickSubTabCategory(category)">{{category.categoryName}}</a>
              </div>
            </div>

            <div class="tit-tab-box" v-else-if="currentTabMenu.childMenus ? currentTabMenu.childMenus.length > 0 : false">
              <a href="javascript:;" v-for="child in currentTabMenu.childMenus" :key="child.boardId"
                 :class="{on:child.menuName === boardName || (menuUrl!=null && child.url!=null && child.url === menuUrl)}"
                 @click="goToChildMenu(child)">
                {{child.menuName}}
              </a>
            </div>

            <div style="margin-left: auto" class="search-mb-short-box">
              <div class="search-short-box" :class="{open:searchOpen}" v-if="(board.boardType =='TYPE_GENERAL' || board.boardType =='TYPE_PHOTO') && componentStatus!='detail'"><!-- open 클래스 추가시 상세 화면 열림 -->
              <div class="sch-word-box">
                <select v-if="param.searchColumn=='category'&&boardId!='567'&&boardId!='569'" class="input-st1 st-selectbox" style="border-top:none;border-right:none;border-left:none;width:205px;" v-model="param.searchCategory">
                  <option v-for="category in board.boardCategories" :key="category.categoryId" :value="category.categoryId">{{category.categoryName}}</option>
                </select>
                <input v-else type="text" placeholder="검색어를 입력해주세요." @keydown="onKeydown" class="input-word" v-model="param.searchKeyword" >
                <a href="javascript:;" class="btn-search" @click="onClickBeforeSearch">검색버튼</a>
                <a href="javascript:;" class="btn-dtl btnBoardSearchDtl" @click="onClickSearchOpen" >상세</a><!-- btnBoardSearchDtl 스크립트 있음 -->
              </div>
              <div class="search-dtl-box ">
                <div class="inner-box">
                  <div class="row-box">
                    <div class="word-tit">기간검색</div>

                    <div class="date-1-box">
                      <DgDatePicker format="YYYY-MM-DD" v-model="param.fromDate" clearable class="input-st1 wd200" style="padding: 0 0" placeholder="yyyy-mm-dd"/>
                      <span class="line">~</span>
                      <DgDatePicker format="YYYY-MM-DD" v-model="param.toDate" clearable class="input-st1 wd200" style="padding: 0 0" placeholder="yyyy-mm-dd"/>
                      <!-- btnDatepickerTwin com_layer 에 스크립트 / 팝업 있음-->
                    </div>
                    <div class="date-2-box">
                      <input type="radio" id="ra0101" name="date_type" @click="selectItem(1)"><label for="ra0101">1개월</label>
                      <input type="radio" id="ra0102" name="date_type" @click="selectItem(3)"><label for="ra0102">3개월</label>
                      <input type="radio" id="ra0103" name="date_type" @click="selectItem(6)"><label for="ra0103">6개월</label>
                      <input type="radio" id="ra0104" name="date_type" @click="selectItem(12)"><label for="ra0104">12개월</label>
                      <input type="radio" id="ra0105" name="date_type" @click="selectItem(0)" checked><label for="ra0105">전체</label>
                    </div>
                  </div>
                  <div class="row-box">
                    <div class="word-tit">조건검색</div>

                    <div class="sch-txt-box">
                      <select name="" id="" class="input-st1 wd150 st-selectbox" v-model="param.searchColumn">
                        <option value="title">제목</option>
                        <option value="contents">내용</option>
                        <option value="writer">작성자</option>
                        <option value="category" v-if="this.boardId!='567'&&this.boardId!='569'">카테고리</option>
                      </select>
                      <select v-if="param.searchColumn=='category'" class="input-st1 wd280 st-selectbox" v-model="param.searchCategory">
                        <option v-for="category in board.boardCategories" :key="category.categoryId" :value="category.categoryId">{{category.categoryName}}</option>
                      </select>
                      <input v-else type="text" class="input-st1 wd280" placeholder="검색어를 입력해주세요." v-model="param.searchKeyword" @keydown="onKeydown">
                    </div>
                  </div>
                  <div class="btn-box"><a href="javascript:;" @click="onClickBeforeSearch">검색</a></div>
                </div>
              </div>
            </div>
            </div>
          </div>

          <div class="mb-only wd100" v-if="group != null && componentStatus=='list'" :class="{open : this.$store.state.tapOpen === true}">
            <div class="btn-round-box"><a href="javascript:;" class="" @click="onClickUser(null, 'list')"><i class="icon-people"></i>{{ menuName.includes('분과')?'분과':'동호회' }}인원</a></div>
          </div>

          <div v-if="componentStatus=='schedule-list'" >
            <schedule :is-component="true" status="calendar" :indexStatus="indexStatus" @click:bgDim="onClickBgDim" @changeStatus="changeStatus"></schedule>
          </div>
          <article-detail v-else-if="componentStatus=='detail'" :boardId="boardId" :articleId="articleId" :isOpen="isOpen"  @click:title="onClickTitle" @click:user="onClickUser" @click:bgDim="onClickBgDim"/>
          <!-- board-tab-box (E) -->
          <div v-else-if="board.boardType=='TYPE_GENERAL' || board.boardType =='TYPE_PHOTO'" >
            <div class="tb-01 st2 mb20"  v-if="board.boardType=='TYPE_GENERAL'" >
              <table>
                <colgroup><col style="width:100px"><col style="width:110px"><col style="width:*"><col style="width:140px"><col style="width:150px"><col style="width:80px"></colgroup>
                <thead>
                <tr>
                  <th>번호</th>
                  <th>카테고리</th>
                  <th>제목</th>
                  <th>작성자</th>
                  <th>작성일</th>
                  <th>조회수</th>
                </tr>
                </thead>
                <tbody>
                <!--  for   -->
                <tr v-for="(article, idx) in articles" :key="article.articleId" class="st-board">
                  <td class="al-center td-pc-only" v-if="article.notice"><em class="notice">상시</em></td>
                  <td class="al-center td-pc-only" v-else>{{ pagination.totalElements - ((pagination.currentPage-1) * pagination.pageSize) - idx }}</td>
                  <td class="al-center td-pc-only">{{ article.boardCategory==null?'전체':article.boardCategory.categoryName }}</td>
                  <td class=" td-pc-only ">
                    <a href="javascript:;" class="ellipsis_inline" style="max-width: 530px" @click="onClickTitle('detail',article.articleId)">{{ article.title }}</a>
                    <div class="tit-icon" style="padding-left: 7px">
                      <span class="cmt" v-if="article.commentCount > 0">[{{ article.commentCount }}]</span>
                      <span class="icon-file" v-if="article.articleAttachments.length>0"></span>
                      <span class="icon-image" v-if="article.contents.includes(findString)" ></span>
                      <span class="icon-new" style="top:1px" v-if="isNew(article.createdAt)"></span>
                    </div>
                  </td>
                  <td class="al-center td-pc-only">
                    <div class="send-msg-menu-box" :class="{open:showMemberDetail(idx)}" @click="showMemberDetailOpen(idx)"><!-- open 클래스 추가 시 버튼 노출 -->
                      <a href="javascript:;" class="btnSendMenu">{{ article.writer }}</a>
                      <div class="btn-list-box">
                        <a href="javascript:;" class="btnPeopleDtlOver" @click="onClickUser(article.createdBy, 'detail')">회원 정보 </a>
                        <a href="javascript:;" class="btnMemoSendOver" @click="onClickUser(article.createdBy, 'message')">쪽지보내기</a>
                      </div>
                    </div>
                    <!-- btnSendMenu 스크립트 있음 -->
                  </td>
                  <td class="al-center td-pc-only">{{ formatDate(article.createdAt) }}</td>
                  <td class="al-center td-pc-only">{{ article.hits==null? 0:article.hits }}</td>
                  <td class="mb-only">
                    <div class="tb-top">
                      <div class="tb-notice" v-if="article.notice"><em class="notice">상시</em></div>
                      <div class="tb-notice" v-else></div>
                      <div class="tb-cate">[{{ article.boardCategory==null?'전체':article.boardCategory.categoryName }}]</div>
                      <div class="tb-title">
                        <a href="javascript:;" class="ellipsis_inline" @click="onClickTitle('detail',article.articleId)">{{ article.title }}</a>
                        <div class="tit-icon" style="padding-left: 7px">
                          <span class="icon-file" v-if="article.articleAttachments.length>0"></span>
                          <span class="icon-image" v-if="article.contents.includes(findString)" ></span>
                          <span class="icon-new" style="top:1px" v-if="isNew(article.createdAt)"></span>
                        </div>
                      </div>
                    </div>
                    <div class="tb-btm">
                      <div class="send-msg-menu-box tb-name" :class="{open:showMemberDetail(idx)}" @click="showMemberDetailOpen(idx)"><!-- open 클래스 추가 시 버튼 노출 -->
                        <a href="javascript:;" class="btnSendMenu">{{ article.writer }}</a>
                        <div class="btn-list-box">
                          <a href="javascript:;" class="btnPeopleDtlOver" @click="onClickUser(article.createdBy, 'detail')">회원 정보 </a>
                          <a href="javascript:;" class="btnMemoSendOver" @click="onClickUser(article.createdBy, 'message')">쪽지보내기</a>
                        </div>
                      </div>
                      <div class="tb-hit">
                        <span><i class="icon-dtl-hit">조회수</i></span>
                        <em>{{ article.hits==null? 0:article.hits }}</em>
                      </div>
                      <div class="tb-date">
                        {{ formatDate(article.createdAt) }}
                      </div>
                      <div class="tb-cmt" v-if="article.commentCount > 0">
                        <span ><i class="icon-dtl-cmt">댓글</i></span>
                        <em v-if="article.commentCount > 0">{{ article.commentCount }}</em>
                      </div>
                    </div>
                  </td>
                </tr>
                <!--  for   -->

                </tbody>
              </table>
            </div>
            <div class="board-photo-list-box" v-else-if="board.boardType=='TYPE_PHOTO'">
              <!--  for   -->
              <div class="row-box" v-for="(article, idx) in articles" :key="article.articleId">
                
                <div class="img-box" @click="onClickTitle('detail',article.articleId)" style="cursor: pointer;" >
                  <v-img :src="article.mainImage.fileServerName ? (article.mainImage.fileContextPath.includes('board')? `/attachment/board/${boardId}/${article.mainImage.thumbnail}` :  `/attachment/article/${article.mainImage.thumbnail}`) : '/photo_default.jpg'"
                         v-on:error.once="article.mainImage.thumbnail='/photo_default.jpg'"
                         max-width="100%" height="184" style="border-radius: 10px;"
                  />
<!--                  <img v-if="!article.thumbnail" src="../../../src/assets/front/images/photo_default.jpg" />-->
<!--                  <img :src="article.thumbnail && article.thumbnail.indexOf('/') === 0 ? `${article.thumbnail}` : `/attachment/board/${boardId}/article/thumb_${article.thumbnail}`"/>-->
                </div>
                <div class="tit-box" @click="onClickTitle('detail',article.articleId)">
                  <a href="javascript:;"><span class="tit ellipsis">{{ article.boardCategory==null?'':'['+article.boardCategory.categoryName+']' }} {{ article.title }}<span class="cmt" v-if="article.commentCount > 0">[{{ article.commentCount }}]</span></span></a>
                </div>
                <div class="date-box">
                  <span class="date">{{ formatDate(article.createdAt) }}</span>
                  <span class="name">
                      <div class="send-msg-menu-box" :class="{open:showMemberDetail(idx)}" @click="showMemberDetailOpen(idx)"><!-- open 클래스 추가 시 버튼 노출 -->
                          <a href="javascript:;" class="btnSendMenu">{{ article.writer }}</a>
                          <div class="btn-list-box">
                              <a href="javascript:;" class="btnPeopleDtlOver" @click="onClickUser(article.createdBy, 'detail')">회원 정보</a>
                              <a href="javascript:;" class="btnMemoSendOver" @click="onClickUser(article.createdBy, 'message')">쪽지보내기</a>
                          </div>
                      </div>
                    <!-- btnSendMenu 스크립트 있음 -->
                </span>
                  <span class="hit"><i class="icon-dtl-hit">조회수 :</i> {{ article.hits==null? 0:article.hits }}</span>
                </div>
                <!--  for   -->
              </div>
            </div>
            <div class="board-btn-box">
              <a href="javascript:;" class="" @click="onClickWrite" v-if="authWrite || authAdmin">글작성</a>
            </div>
            <!-- class : pagination (S) -->
            <DgPagination v-model="pagination" @pageSelected="pageSelected"/>
            <!-- class : pagination (E) -->
          </div>

        </div>
      </div>
      <!-- class : main-wrap (E) -->

      <CommonPopup v-if="memberList" :tap="popupTab" :userId="userId" :groupId="group!=null?group.groupId:null" @click:bgDimClose="popupMemberList"></CommonPopup>

    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->
</template> 
<script>
import moment from 'moment';
import DgDatePicker from '@/views/component/DgBaseDatepicker'
import CommonPopup from '@/views/include/common_popoup'
import menuApi from "@/api/MenuApi";
import boardApi from "@/api/BoardApi"
import ArticleDetail from "./board_detail";
import articleApi from "@/api/ArticleApi";
import DgPagination from '@/views/component/DgPagination'

import Schedule from "@/views/meeting/schedule";
import groupApi from "@/api/GroupApi";

const CONST_SCHEDULE_BOARD_NAME = '분과일정';

export default {
  name: "BoardIndex",
  components:{
    DgDatePicker,
    CommonPopup,
    'article-detail' : ArticleDetail,
    Schedule,
    DgPagination
  },
  props:{
    isOpen:{
      type:Boolean,
      default:false,
    }, 
  },
  data(){
    return {
      subTabCategory:null,
      returnClass:'st-division',
      findString: '<img',
      ////
      menuName:'',
      board:{},
      articleId:0,
      ////
      articles:[],
      activeItem: null,
      indexStatus: null,

      parentMenu: null,
      boardId: 0,
      boardName: '',
      componentStatus:   'list', 
      menuUrl:null,
      status: '',
      searchOpen: false,
      param: {
        searchKeyword:'',
        searchColumn:'title',
        fromDate:   '',
        toDate:     '',
        size:0,
        page:0,
        searchCategory: null
      },


      tabs: [],

      currentTabMenu: {},
      memberList: false,
      popupTab: 'list',

      chairman:'',
      secretary:'',
      group:{},
      userId:null,
      memberOpen:false,
      rowIndex:null,


      pagination:{
        currentPage: 1,
        pageSize: 0,
        pageGroupSize: 10,
        totalPages:0,
        totalElements:0
      },
      permissionMap:null, 
      subdivisionId :  427,
      clubId : 428,
      menuId : null,
      tablistOpen:false,
    }
  },
  computed:{
    authWrite(){
      let auth = false;
      if((this.permissionMap?.['WRITE']??[]).filter(x => (this.$store.state?.user?.roles??[]).map(r => parseInt(r)).includes(x)).length != 0){
        auth = true;
      }
      return auth;
    },
    authAdmin(){
      let auth = false;
      if((this.permissionMap?.['ADMIN']??[]).filter(x => (this.$store.state?.user?.roles??[]).map(r => parseInt(r)).includes(x)).length != 0){
        auth = true;
      }
      return auth;
    },
    authRead(){
      let auth = false;
      if((this.permissionMap?.['READ']??[]).filter(x => (this.$store.state?.user?.roles??[]).map(r => parseInt(r)).includes(x)).length != 0){
        auth = true;
      }
      return auth;
    }
  },
  created(){
      this.componentStatus =   (typeof  this.$route.query.c  !== 'undefined') ?  this.$route.query.c :  'list';
       this.param.searchKeyword = (typeof  this.$route.query.s   !== 'undefined') ?  this.$route.query.s  : ''
       this.param.searchColumn  =(typeof  this.$route.query.t   !== 'undefined') ?  this.$route.query.t  :  'title';
       this.param.searchCategory  =(typeof  this.$route.query.g   !== 'undefined') ?  this.$route.query.g  :  null;
       this.subTabCategory = this.param.searchCategory;

       this.param.fromDate  = (typeof  this.$route.query.fd  !== 'undefined') ?  this.$route.query.fd :'';
       this.param.toDate   = (typeof  this.$route.query.td  !== 'undefined') ?  this.$route.query.td : '';

       this.pagination.currentPage   = (typeof  this.$route.query.cp  !== 'undefined') ?  Number(this.$route.query.cp) : 1;
       this.pagination.pageSize   = (typeof  this.$route.query.ps  !== 'undefined') ?   Number(this.$route.query.ps) : 10;
      
      if(this.pagination.currentPage > 0){
            
        this.$nextTick(()=>{
          setTimeout(function (){window.scrollTo(0, 0) ; }, 100);
        })
      }

   
    this.articleId = (typeof  this.$route.query.at  !== 'undefined') ?  this.$route.query.at :  0;
 
    if(this.componentStatus == 'schedule-list') {
     this.menuUrl = '/meeting/schedule';
     this.boardName  = "분과일정";
    }
   
  //const that = this; 
    this.getReady();

  }, 
  destroyed() { 
 //   console.info('destroyed');
  },
  watch:{
    $route(){
      this.menuUrl = null;
      this.boardName = null;
      this.boardId = null;
     // this.getReady();
    },
    isOpen(e){
      if(!e){
        this.memberList=false
      }
    }
  },
  methods:{
   async getReady(){
      this.boardId = this.$route.params.id;
      this.parentMenu = this.$route.query.upper;
      if(this.parentMenu==427){
        this.returnClass='st-division'
      }else if(this.parentMenu==428){
        this.returnClass='st-club'
      }else if(this.parentMenu==431){
        this.returnClass='st-community'
      }
 
      let newMenuMap = this.$store.state.menuInfoData  == null ? {}  : this.$store.state.menuInfoData  ;

      if(newMenuMap[this.parentMenu] == null){ 
              await menuApi.getMenu(this.parentMenu).then(data=>{ 
                newMenuMap[this.parentMenu] = data; 
                this.$store.commit('setMenuInfoData', newMenuMap); 
              });

        }
        
      this.initMenuInfo(newMenuMap[this.parentMenu]);

      let childs = this.$store.state.menuInfoData[this.parentMenu].childMenus; 

      const that = this;
      const found = childs.find(element => element.boardId == that.boardId);

      let newboardMap =  this.$store.state.boardInfoData == null ? {} : this.$store.state.boardInfoData ;
 
      if(found)
      {
        for(let i=0; i <  childs.length ; i++) {
          const item = childs[i];

          if (item.boardId != null && newboardMap[item.boardId] == null) {
            await boardApi.get(item.boardId).then(data => {
              newboardMap[Number(data.boardId)] = data;
              this.$store.commit('setBoardInfoData', newboardMap);
            });
          }
        }
      }
      else{
        await boardApi.get(this.boardId).then(data => {
          newboardMap[Number(data.boardId)] = data;
          this.$store.commit('setBoardInfoData', newboardMap);
        });
      }

      this.initBoardInfo(newboardMap[this.boardId]);  
 
      this.onClickSearch();

    },
    initMenuInfo(data) {
        this.menuId = data.menuId;
        this.menuName=data.menuName;
        this.tabs = data.childMenus;    //ce분과, it분과, 디스플레이분과 etc.
        this.currentTabMenu = this.tabs.find(data=> data.childMenus.find(d=> d.boardId==this.boardId))

        this.boardName = this.currentTabMenu.childMenus.find(d=>d.boardId==this.boardId).menuName

        if(this.componentStatus == 'schedule-list') {
            this.boardName = CONST_SCHEDULE_BOARD_NAME;// '분과일정';
        }
    },
    initBoardInfo(data) { 
        this.board = data;

        if(this.board.boardCategories && this.board.boardCategories.length > 0) {
          this.board.boardCategories = [{
            'categoryId': null,
            'categoryName': "전체",
            'orderNo': 0
          }, ...this.board.boardCategories.filter(c => c.categoryId !== null)]
        }

        this.permissionMap = this.board.boardPermissions.reduce((result, item) => {
          result[item.id.functionType] = [...result[item.id.functionType]??[], item.id.roleId];
          return result;
        }, {});

        this.group = data.group;
        this.getGroupInfo();
    },
    pageSelected(pagination){
      this.pagination.currentPage = pagination.currentPage;

      const query = { upper: this.parentMenu, menu: this.currentTabMenu.menuId, c: 'list'
      , cp: this.pagination.currentPage
      , ps: this.pagination.pageSize
       };

      if( this.param.searchColumn == 'category' ){
        this.param.searchKeyword='';
      }else if(this.boardId!='567'&&this.boardId!='569'){
        this.param.searchCategory = null;
      }

      query['s'] =  this.param.searchKeyword;
      query['t'] =  this.param.searchColumn;
      query['g'] =  this.param.searchCategory;

      this.param.fromDate != '' && (query['fd'] = moment( this.param.fromDate).format('YYYY-MM-DD') );
      this.param.toDate != '' && (query['td'] =  moment( this.param.toDate).format('YYYY-MM-DD'));


      this.$router.push({path: "/board/" +  this.boardId, query:query });

     // this.onClickSearch();
    },
    getGroupInfo(){
      if(this.group != null) {
        this.chairman = '';
        this.secretary = '';

        groupApi.getGroup(this.group.groupId).then(data => {
          let staffUsers = data.staffUsers;
          let chairmanTemp = staffUsers.filter(x => x.groupPosition == 'chairman');
          let secretaryTemp = staffUsers.filter(y => y.groupPosition == 'secretary');

          for (let i = 0; i < chairmanTemp.length; i++) {
            this.chairman += chairmanTemp[i].name;
            if (i != chairmanTemp.length - 1) {
              this.chairman += ", ";
            }
          }
          for (let i = 0; i < secretaryTemp.length; i++) {
            this.secretary += secretaryTemp[i].name;
            if (i != secretaryTemp.length - 1) {
              this.secretary += ", ";
            }
          }
        })
      }
    },
    showMemberDetail(idx){
      if(this.rowIndex === idx){
        return true;
      }
    },
    showMemberDetailOpen(idx){
      this.memberOpen = false;
      if(this.rowIndex == idx){
        this.memberOpen = !this.memberOpen;
        this.rowIndex = -1;
      } else {
        this.rowIndex = idx;
      }
    },
    onClickUser(userId, popStatus){
      this.userId=userId
      this.popupTab=popStatus
      this.popupMemberList(true)
    },
    formatDate(value) {
      return moment(value).format('YYYY.MM.DD');
    },
    onClickSearchOpen(){
      this.searchOpen = !this.searchOpen
    },
    onClickTab(tab){
      let url = tab.url;
      if(tab.menuType === 'TYPE_BOARD') {
        url = `/board/${tab.boardId}`;
      }
      this.$router.push({path: url+`?upper=${this.parentMenu}&menu=${tab.menuId}`}).catch(()=>{});
    },
    menuParse(m){
      if(!m.activated) {
        return null;
      }
      if(m.menuType === 'TYPE_BOARD') {
        m.url = `/board/${m.boardId}`;
      }
      return {
        menu: m,
        id : m.menuId,
        protected : m.protected,
        title : m.menuName,
        icon : m.menuIcon,
        href : m.url??null,
        child : m?.childMenus?.length??0 > 0 ? m?.childMenus?.map(this.menuParse).filter(c => c !== null) : null
      }
    },
    popupMemberList(e){
      this.memberList = e
      this.$emit('click:bgDim', this.memberList)
    },
    onKeydown(e){
      if(e.keyCode===13) {
        this.pagination.currentPage = 1;

        if (this.searchCondValiadator() == false)
          return;

        let query = {upper: this.parentMenu, menu: this.currentTabMenu.menuId, c: this.componentStatus};

        if( this.param.searchColumn == 'category' ){
          this.param.searchKeyword='';
        }else if(this.boardId!='567'&&this.boardId!='569') {
          this.param.searchCategory = null;
        }



        if(this.boardId=='567'||this.boardId=='569') {
          query['g'] = this.subTabCategory;
        }else{
          query['g'] = this.param.searchCategory;
        }
        query['s'] = this.param.searchKeyword;
        query['t'] = this.param.searchColumn;

        this.param.fromDate != '' && (query['fd'] = moment(this.param.fromDate).format('YYYY-MM-DD'));
        this.param.toDate != '' && (query['td'] = moment(this.param.toDate).format('YYYY-MM-DD'));

        this.$router.push({path: "/board/" + this.boardId, query: query});

        //this.onClickSearch();
      }
    },
    onClickBeforeSearch(){
      //this.onClickSearch();
      this.onKeydown({keyCode:13});
    },
    onClickSearch() {
      if(this.$route.params.articleId){
        this.articleId = this.$route.params.articleId;
      //@kang1  this.componentStatus = 'detail'
      }else {
        this.pagination.pageSize = this.board.pageSize!=0? this.board.pageSize: (this.componentStatus == 'list' ? 10 : 8);
        if( this.searchCondValiadator() == false)
            return;

        this.searchOpen = false
        articleApi.list(this.boardId, this.getSearchParam()).then(data => {
          this.articles = data.content;
          this.pagination.totalElements = data.totalElements
          let totalPages = Math.floor(data.totalElements / this.pagination.pageSize) + ((data.totalElements % this.pagination.pageSize) > 0 ? 1 : 0);
          this.pagination.totalPages = totalPages;
 
        });
      }
    },
    searchCondValiadator(){
        let frDttm = this.param.fromDate;
        let toDttm = this.param.toDate;
        if ((frDttm != '' && toDttm == '') || (frDttm == '' && toDttm != '')) {
          alert('기간 검색 시작일과 종료일을 모두 입력해주세요.');
          return false;
        }
        return true;
    },
    getSearchParam(){
        let requestParams = {};

        this.param.size = this.pagination.pageSize;
        this.param.page = this.pagination.currentPage;
        
        requestParams['size']=this.pagination.pageSize;
        requestParams['page']=this.pagination.currentPage;

       // const params = {...this.param};
        requestParams['searchCategory'] = this.param.searchCategory;
        requestParams['searchKeyword'] =  this.param.searchKeyword;
        requestParams['searchColumn'] =  this.param.searchColumn;

        if(this.param.fromDate != '') {
          requestParams['fromDate'] =  moment(this.param.fromDate).format('YYYY-MM-DD') ;
          requestParams['toDate'] =   moment(this.param.toDate).format('YYYY-MM-DD') ;
        }
        return requestParams;
    },
    onClickTitle(status, articleId){
      if(!this.authRead && !this.authAdmin){
        return alert("조회 권한이 없습니다.");
      }

      this.$router.push({path: "/board/" +  this.boardId, query: { upper: this.parentMenu, menu: this.currentTabMenu.menuId, c: 'detail' , at: articleId , g: this.param.searchCategory}});
    },
    onClickWrite(){
      this.articleId=null 
      this.$router.push({path: "/board/write/" +  this.boardId, query: { upper: this.parentMenu, menu: this.currentTabMenu.menuId, c: 'write'  }});
     /*  this.componentStatus='write' */
    },
    onClickBgDim(e){
      this.isOpen = e;
      this.$emit('click:bgDim', e)
    },

    /*******************************
     * 기간 조회버튼 클릭
     *******************************/
    selectItem(months) {
      let moment = require('moment')

      this.activeItem = months
      if (this.activeItem !== 0) {
        this.param.fromDate = moment().subtract(months,'months').format('YYYY-MM-DD')
        this.param.toDate = moment().format('YYYY-MM-DD')
      }else{
        this.param.fromDate = ''
        this.param.toDate = ''
      }
    },


    onClickShowMember(userId, popStatus){
      this.$emit('click:user',userId,popStatus)
    },
    goToChildMenu(child){
      if(this.$route.params.articleId){
        this.$route.params.articleId=null;
      }

      this.pagination.currentPage=1
      this.param.searchKeyword= '',
      this.param.searchColumn= 'title',
      this.param.fromDate= '',
      this.param.toDate= '',
      this.param.page= 1
      this.menuUrl = null;
      this.boardId = child.boardId
      this.boardName = child.menuName
     // this.boardId = null;

      // console.log(child);

      if(child.url === '/meeting/schedule') {
       //@kang1 this.componentStatus= 'schedule-list'
       //alert('this.boardId ' + this.boardId);
        this.$router.push({path: "/board/" +  this.$route.params.id, query: { upper: this.parentMenu, menu: this.currentTabMenu.menuId, c: 'schedule-list', groupId: this.group.groupId }}).catch(()=>{});
        this.indexStatus = 'calendar';

        //this.menuUrl = child.url;
      } else {
       
        boardApi.get(child.boardId).then(data => {
          this.board = data;
          if (this.board.boardType === 'TYPE_GENERAL' || this.board.boardType === 'TYPE_SCHEDULE') {
            this.$router.push({path: "/board/" +  this.boardId, query: { upper: this.parentMenu, menu: this.currentTabMenu.menuId, c: 'list' }});  
          } else if (this.board.boardType === 'TYPE_PHOTO') {
            this.$router.push({path: "/board/" +  this.boardId, query: { upper: this.parentMenu, menu: this.currentTabMenu.menuId, c: 'photo' }});  
          }
        });
      }

    },
    onClickSubTabCategory(category){
      this.subTabCategory = category.categoryId;
      this.componentStatus = 'list';
      this.param.searchKeyword = '';
      this.param.searchColumn = 'title';

      this.onClickBeforeSearch();
    },
    isNew(val){
      let now = moment(new Date());
      let end = moment(val);
      let isNew = now.diff(end, 'days');
      if(isNew < this.board.newPeriod) {
        return true;
      }
    },
    changeStatus(){
      this.indexStatus = null;
    },
    /*탭 오픈*/
    openTab(){
     this.$store.commit('setTapOpen')
    },
    onClickTabMobile(){
      if(this.tablistOpen){
        this.tablistOpen = false;
      }else{
        this.tablistOpen = true;
      }
    },
    goback(){
      this.$router.go(-1);
    }

  },
}
 
</script>

<style>

</style>