<template>
  <!-- id : contents : (S) -->
  <div id='contents' >
    <AccountPopup v-if="popupAccount" @click:bgDimClose="onClickPopupAccount" :isOpen="isOpen"
                  :bank="article.bank" :name="article.accountHolder" :account="article.accountNumber"></AccountPopup>

    <v-app>
        <!-- board-dtl-box (S) -->
        <div class="board-dtl-box">
          <div class="hd-box">
            <div class="tit" style="position: relative">
              <span if="article.notice">{{ getCategoryName(article.categoryId) }} </span>
              <!--<span class="board-dtl-like-off">{{ article.title }}</span>-->
              {{ article.title }}
            </div>
            <div class="exp">
              <span class="date">{{ formatDate(article.createdAt) }}</span>
              <span class="date">
                <div class="send-msg-menu-box" :class="{open:showMemberDetail}" @click="showMemberDetail = !showMemberDetail"><!-- open 클래스 추가 시 버튼 노출 -->
                  <a href="javascript:;" class="date btnSendMenu" style="color:#666666">{{ article.writer }}</a>
                  <div class="btn-list-box">
                    <a href="javascript:;" class="btnPeopleDtlOver" @click="onClickShowMember(article.createdBy, 'detail')">회원 정보</a>
                    <a href="javascript:;" class="btnMemoSendOver" @click="onClickShowMember(article.createdBy, 'message')">쪽지보내기</a>
                  </div>
                </div>
              </span>

              <span class="date"><i class="icon-dtl-hit">조회</i> {{ article.hits==null? 1:article.hits }}</span>
              <span class="date" v-if="boardId!='567'"><i class="icon-dtl-fav">좋아요</i> {{ article.reactionCount==null? 0:article.reactionCount }}</span>
            </div>
          </div>
          <div class="text-body-box">
<!--              <viewer-quill :val="article.contents" />-->
<!--              <quill-editor v-model="article.contents" ref="contents" :options="editorOption" :disabled="true"/>-->
              <div v-viewer>
              <div v-html="article.contents" class="ql-editor ql-container ql-disable"></div>
              </div>
          </div>
          <!-- 2021-03-08 add -->
          <div class="bank-info-btn-box">
            <a href="javascript:;" class="btnBankInfoDtl btn-line" v-if="article.accountNumber" @click="onClickPopupAccount(true)"  >계좌정보 확인</a>
            <!-- 회원정보 추가 -->
            <a href="javascript:;" class="btnBankInfoDtl btn-line" v-if="article.targetUser" @click="onClickUser(article.targetUser.userId, 'detail')"  >회원정보 확인</a>
          </div>
          <!-- 2021-03-08 add -->
          <div v-if="article.articleAttachments.length>0" class="attach-list-box">
            <ul>
              <li v-for="attach in article.articleAttachments" :key="attach.attachId">
                 <v-btn text  @click.prevent="downloadFile(attach)"><v-icon>{{ attach.contentType.indexOf('image') === 0 ? 'mdi-image' : 'mdi-file' }}</v-icon>
                  <v-tooltip bottom v-if="attach.contentType.indexOf('image') === 0 ">
                    <template v-slot:activator="{ on, attrs }">
                      <span
                          v-bind="attrs"
                          v-on="on"
                      >
                        {{ attach.fileRealName }}{{attach.fileRealName.indexOf('에디터')}}
                      </span>
                    </template>

                    <span>
                      <v-img :src="`/attachment/${attach.fileContextPath}${attach.thumbnail}`" max-height="200" max-width="200"/>
                    </span>
                  </v-tooltip>
                  <span v-else>{{ attach.fileRealName }}</span>
                </v-btn>
                <!--                <a :href="`${apiEndPoint}/board/${article.boardId}/article/${article.articleId}/file/${attach.fileId}`">{{ attach.fileRealName }}</a>-->
              </li>
            </ul>
          </div>
          <div class="btn-edit-box">
            <a href="javascript:;" class="st1" @click="onClickRevise" v-if="($store.state.user.userid==article.createdBy && authWrite) || authAdmin">수정</a>
            <a href="javascript:;" class="st2" @click="onClickDelete" v-if="$store.state.user.userid==article.createdBy || authAdmin">삭제</a>
          </div>
        </div>
        <!-- board-dtl-box (E) -->

        <!-- comment-wrap (S) -->
        <div class="comment-wrap" v-if="board.hasComment">
          <div class="hd-box"  style="margin-bottom: 20px;">댓글 <em>{{ commentsCnt }}</em>
            <span v-if="boardId!='567'">
              <img v-if="article.readUserHasReaction" class="board-dtl-like" @click="onClickLike()" src="../../../src/assets/front/images/icon/icon-heart-on.png"/>
              <img v-else class="board-dtl-like" @click="onClickLike(article.articleId)" src="../../../src/assets/front/images/icon/icon-heart-off.png" />
              좋아요
              <em>{{article.reactionCount}}</em>
            </span>
          </div> 
          <!-- 댓글 시작 -->
          <Comment
            v-for="(comment, key) in comments"
            :key="key"
            :id="comment.commentId"
            :createId="comment.createdBy"
            :author="comment.writer"
            :date="comment.createdAt"
            :content="comment.contents"
            :children="comment.childrenList"
            :totalChildren="comment.totalChildren"
            :isDeleted="comment.deleted"
            :upcomment="comment" 
            :reCommentIndex="reCommentIndex"
            :isVisibleCommentUpdateForm="isVisibleCommentUpdateForm"
            :isVisibleReplyEditor="isVisibleReplyEditor"
            :isAuthAdmin="authAdmin"
            :isAuthComment="authComment"
            @submit:reply="onSubmitReply"
            @update:comment="onUpdateReply"
            @remove="onClickRemoveComment" 
            ></Comment>
          <!-- 댓글 종료 -->
 
          <div class="reg-box" v-if="$store.state.user.userid!=null">
            <div class="name">{{ $store.state.user.username }}</div>
            <div class="text-box"><textarea v-model="comment.contents" cols="30" rows="10" placeholder="댓글을 남겨보세요." style="resize: none;"></textarea></div>
            <a href="javascript:;" class="btn-reg" @click="saveComment">등록</a>
          </div>
        </div>
        <!-- comment-wrap (E) -->

        <div class="board-dtl-box">
          <div class="btn-edit-box" style="text-align:center; margin: 60px 0 0 0;">
            <a href="javascript:;" class="st2" @click="goToList">목록</a>
          </div>
      </div>
      <!-- class : main-wrap (E) -->
    </v-app>
    

    <CommonPopup v-if="memberList" :tap="popupTab" :userId="userId" :groupId="group!=null?group.groupId:null" @click:bgDimClose="popupMemberList"></CommonPopup>

  </div>
</template>

<script>
import '@/assets/style/quill.font.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import articleApi from '@/api/ArticleApi';
import moment from "moment";
import boardApi from "@/api/BoardApi";
import commentApi from "@/api/CommentApi";
import AccountPopup from "@/views/board/account_popup";
import CommonPopup from '@/views/include/common_popoup';
import Comment from '@/components/Comment';
//import Comment from "@/views/board/Comment"; 
//import ViewerQuill from "@/views/component/ViewerQuillComponent";
 
export default {
  name: "NoticeDetail",
  props: {
    articleId:{
    },
    boardId:{
    },
    isOpen:{
      type:Boolean,
      default:false
    },
    subs:{}
  },
  components:{
  //  ViewerQuill,
    AccountPopup,
    CommonPopup,
    Comment,
  },
  data(){
    return {
      editorOption: {
        placeholder: '',
        readOnly: true,
        theme: '',
        modules:{
          toolbar:false
        }
      },
      article: {articleAttachments : []},
      memberList: false,
      popupTab: 'list',
      parentMenu: null,
      menuId: null,
      status: '',
      userId: null,
      showMemberDetail: false,
      board:{},
      commentsCnt:0,
      comments:{},
      comment: {
        contents:""
      },
      editComment:{
        contents:""
      },
      editCommentStatus:false,
      commentIndex: -1,
      reCommentIndex : -1, 
      apiEndPoint:process.env.VUE_APP_BASE_API,

      permissionMap:null,
      popupAccount:false,


      isVisibleCommentUpdateForm : false,
      isVisibleReplyEditor:false,
    }
  },
  watch:{
    $route(){
      this.parentMenu = this.$route.query.upper;
      this.onClickSearch();
    },
    isOpen(e){
      if(!e){
        this.memberList=false;
        this.popupAccount=false;
      }
    }
  },
  computed:{
    authWrite(){
      let auth = false;
      if((this.permissionMap?.['WRITE']??[]).filter(x => (this.$store.state?.user?.roles??[]).map(r => parseInt(r)).includes(x)).length != 0){
        auth = true;
      }
      return auth;
    },
    authAdmin(){
      let auth = false;
      if((this.permissionMap?.['ADMIN']??[]).filter(x => (this.$store.state?.user?.roles??[]).map(r => parseInt(r)).includes(x)).length != 0){
        auth = true;
      }
      return auth;
    },
    authComment(){
      let auth = false;
      if((this.permissionMap?.['COMMENT']??[]).filter(x => (this.$store.state?.user?.roles??[]).map(r => parseInt(r)).includes(x)).length != 0){
        auth = true;
      }
      return auth;
    }
  },
  mounted(){
    this.$nextTick(()=>{
      setTimeout(function (){window.scrollTo(0, 0) ; }, 100);
    })
  },
  created(){
    this.parentMenu = this.$route.query.upper;
    this.menuId = this.$route.query.menu;

    boardApi.get(this.boardId).then(data => {
      this.board = data;

      this.permissionMap = this.board.boardPermissions.reduce((result, item) => {
        result[item.id.functionType] = [...result[item.id.functionType]??[], item.id.roleId];
        return result;
      }, {});
    });
    articleApi.get(this.boardId, this.articleId).then(data => {
      this.article = data;
      this.article.articleAttachments = data.articleAttachments.filter(this.filefilters);
      // 갯수 안맞아서 수정함 
      this.commentsCnt = data.commentCount;
      //console.log("data")
      //console.log(data)
    });
    this.search();
  },
  methods:{
    onSave(){
      //console.log("--------------------1");
    },
    filefilters(value){
      if(value.fileRealName.includes('모바일 첨부파일_') || value.fileRealName.includes('에디터 첨부파일_')){
        return false;
      }else return true;
    },
    downloadFile(f) {

      articleApi.downloadFile(this.article.boardId, this.article.articleId, f.fileId)
          .then(response => {
            const blob = new Blob([response.data], { type: response.headers['content-type'] })

            if(navigator.appVersion.toString().indexOf('.NET') > 0)
              window.navigator.msSaveOrOpenBlob(blob, f.fileRealName.slice(f.fileRealName.lastIndexOf('/') + 1));
            else {

              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = f.fileRealName.slice(f.fileRealName.lastIndexOf('/') + 1);
              link.click()
              URL.revokeObjectURL(link.href)

            }
          })
          .catch(error => {
            // console.log(error.response);
            // console.log(error.response.status);
            if(error.response.status === 404)
              alert("파일을 찾을 수 없습니다.");
            else
              alert(error.status);
          });
    },
    // downloadFile(a, fid) {
    //   console.log(JSON.stringify(a.boardId), JSON.stringify(a.articleId), JSON.stringify(fid))
    //   articleApi.downloadFile(a.boardId, a.articleId, fid).catch(error => {
    //     console.log(error.response);
    //     if(error.response.data.trace.indexOf("NoSuchFileException") !== -1)
    //       alert("NoSuchFileException");
    //     else
    //       alert(error.status);
    //   });
    // },
    formatDate(value) {
      return moment(value).format('YYYY.MM.DD');
    },
    async onClickDelete(){
      if(confirm('지금 삭제하시면 내용을 복구할 수 없습니다.\n 삭제하시겠습니까?')) { 
        this.$route.params.articleId=null;
        articleApi.delete(this.boardId, this.article.articleId).then(() => { 
            this.$router.go(-1);
        })
      }
    },
    onClickSearchOpen(){
      this.searchOpen = !this.searchOpen
    },
    onClickRevise(){
       this.$router.push({path: "/board/write/" +  this.boardId, query: { upper: this.parentMenu, menu: this.menuId, c: 'write' , at: this.articleId }});
    },
    popupMemberList(e){
      this.memberList = e
      this.$emit('click:bgDim', this.memberList)
    },
    changePopupTab(val){
      this.popupTab = val
    },
    onClickShowMember(userId, popStatus){
      this.$emit('click:user',userId,popStatus)
    },
    onClickLike(){
      articleApi.reaction(this.article.articleId, this.article.boardId).then(res => {
        if(res.data) {
 //         this.article.reactionCount = this.article.reactionCount +1;
          this.article.readUserHasReaction = true;
        } else {
 //         this.article.reactionCount = this.article.reactionCount -1;
          this.article.readUserHasReaction = false;
        }
        //게시글 상세조회 재호출
        articleApi.get(this.boardId, this.articleId).then(data => {
          this.article = data;
          this.article.articleAttachments = data.articleAttachments.filter(this.filefilters);
        });
        this.search();
      });


    },
    goToList(){
      //let boardType = this.boardType=='TYPE_GENERAL'?'list':'photo'
      //this.$route.params.articleId=null;
      //this.$emit('click:title',boardType,null)
      if(this.$route.query.fr=='main'){
        this.$router.push(`/board/${this.$route.params.id}?upper=${this.$route.query.upper}&menu=${this.$route.query.menu}`)
      }else {
        this.$router.go(-1);
      }
    },
    async saveComment() {
      if(this.comment.contents.length==0){
        return alert('댓글을 한 글자 이상 입력해주세요.')
      }

      if(!this.authComment && !this.authAdmin) {
        alert("댓글 권한이 없습니다.");
        return;
      }

      this.commentIndex = -1;
      this.editCommentStatus = false;
      this.comment.articleId = this.articleId;
      this.comment.boardId = this.boardId; 
      this.comment.upperCommentId = null;

      commentApi.save(this.boardId, this.comment).then(() => {
        this.comment.contents = "";
        this.search();
      })
    },
    onSubmitReply(reply, id){  
      let tempContents = reply;
      if(tempContents.length==0){
        return alert('댓글을 한 글자 이상 입력해주세요.')
      }

      if(!this.authComment && !this.authAdmin) {
        alert("댓글 권한이 없습니다.");
        return;
      }
      this.comment.articleId = this.articleId;
      this.comment.boardId = this.boardId;
      this.comment.upperCommentId = id;
      this.comment.commentId = null;
      this.comment.createdAt = null;
      this.comment.contents = tempContents;  

      commentApi.save(this.boardId, this.comment).then(() => {
        this.reply = "";
        this.isVisibleReplyEditor = false;
        this.comment.contents = "";
        this.reCommentIndex = -1;
        this.search();
      }) 
    },
    onUpdateReply(editreply, id, upcomment){   
      this.comment = upcomment;
      let tempContents = editreply;
      if(tempContents.length==0){
        return alert('댓글을 한 글자 이상 입력해주세요.')
      }

      if(!this.authComment && !this.authAdmin) {
        alert("댓글 권한이 없습니다.");
        return;
      } 
      this.comment.articleId = this.articleId;
      this.comment.boardId = this.boardId;
      this.comment.commentId = id; 
      this.comment.contents = tempContents; 
      this.comment.updateAt = null;
      commentApi.save(this.boardId, this.comment).then(() => {
        this.editreply = "";
        this.comment.contents = "";
        this.reCommentIndex = -1;
        this.isVisibleCommentUpdateForm = false;
        this.search();
      }) 
    },   
    onClickRemoveComment(id){ 
      if(confirm('지금 삭제하시면 \n내용을 복구할 수 없습니다. 삭제하시겠습니까?')) {
        commentApi.delete(this.boardId, id).then(() => {
          this.search();
        });
      }
    },  
    search() {
      let params = {
          size: -1
      };
      commentApi.list(this.boardId, this.articleId, params).then(data => {
        this.comments = data.content;
      }); 
      commentApi.total(this.boardId, this.articleId).then(data => {
        //갯수 안맞아서 수정함
        this.commentsCnt = data;
      });
    },
    blurEditComment(e){
      this.editComment.contents = e.target.value
    },
    getCategoryName(val){
      let categoryName = '';
      if(val != null){
        categoryName = '['+ this.board.boardCategories.find(d=>d.categoryId==val).categoryName +']';
      }
      return categoryName;
    },
    onClickPopupAccount(e){
      this.popupAccount = e;
      this.isOpen = e;
      this.$emit('click:bgDim', this.popupAccount)
    },
    onClickUser(userId, popStatus){
      this.userId=userId
      this.popupTab=popStatus
      this.popupMemberList(true)
    },
  }
}
</script>

<style scoped>
</style>