import axios from 'axios';
import {clarify} from "../utils/clear";

class BoardApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
    }


    list(body) {
        const params = new URLSearchParams(clarify(body));
        return axios.get(`${this.endpoint}/board`, {params}).then(response=>response.data);
    }

    get(boardId) {
        return axios.get(`${this.endpoint}/board/${boardId}`, {}).then(response=>response.data);
    }

    save(board) {
        return axios.post(`${this.endpoint}/board/save`, board).then(response=>response.data);
    }

    delete(boardId) {
        return axios.delete(`${this.endpoint}/board/delete?boardId=${boardId}`, {}).then(response=>response.data);
    }

    uploadImage(f) {
        const formData = new FormData();
        formData.append('file', f);

        // return new Promise(resolve => resolve({ url: 'https://uicdn.toast.com/toastui/img/tui-editor-bi-white.png', desc:null}));
        return axios.post(`${this.endpoint}/board/upload/image`, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => response.data);
    }

}


export default new BoardApi();
