import axios from 'axios';

class MeetingUserApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
    }


    list(meetingId) {
        return axios.get(`${this.endpoint}/group/meeting/${meetingId}/users`, {}).then(response=>response.data);
    }

    join(meetingId, meetingHasUser) {
        return axios.post(`${this.endpoint}/group/meeting/${meetingId}/join`, meetingHasUser).then(response=>response.data);
    }
    joinedUser(meetingId) {
        return axios.get(`${this.endpoint}/group/meeting/${meetingId}/joined`, {}).then(response=>response.data);
    }

    completeDeposit(meetingId, userId) {
        return axios.put(`${this.endpoint}/group/meeting/${meetingId}/deposit/${userId}`, {}).then(response=>response.data);
    }

    changeDeposit(meetingId, userId) {
        return axios.put(`${this.endpoint}/group/meeting/${meetingId}/change/deposit/${userId}`, {}).then(response=>response.data);
    }
    changeRefund(meetingId, userId) {
        return axios.put(`${this.endpoint}/group/meeting/${meetingId}/change/refund/${userId}`, {}).then(response=>response.data);
    }
    changeAttendance(meetingId, userId) {
        return axios.put(`${this.endpoint}/group/meeting/${meetingId}/change/attendance/${userId}`, {}).then(response=>response.data);
    }


}


export default new MeetingUserApi();
