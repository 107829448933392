import axios from 'axios';
import {clarify} from "../utils/clear";

class ArticleApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
    }


    list(boardId, body) {
        const params = new URLSearchParams(clarify(body));
        return axios.get(`${this.endpoint}/board/${boardId}/articles`, {params}).then(response=>response.data);
    }

    get(boardId, articleId) {
        return axios.get(`${this.endpoint}/board/${boardId}/articles/${articleId}`, {}).then(response=>response.data);
    }

    save(article, files) {
        // return axios.post(`${this.endpoint}/board/save`, board).then(response=>response.data);
        const formData = new FormData();
        for (const f of files) {
            formData.append('files', f);
        }
        //console.log("넘어간 article 값");
        //console.log(article);console.log(article);
        formData.append('article', new Blob([JSON.stringify(article)], { type: "application/json"}));
        //console.log(new Blob([JSON.stringify(article)], { type: "application/json"}))
        //console.log(formData)
        return axios.post(`${this.endpoint}/board/${article.boardId}/article/save`, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response=>response.data);
    }

    delete(boardId, articleId) {
        return axios.delete(`${this.endpoint}/board/${boardId}/delete/article/${articleId}`, {}).then(response=>response.data);
    }

    uploadImage(boardId, f) {
        const formData = new FormData();
        formData.append('file', f);

        // return new Promise(resolve => resolve({ url: 'https://uicdn.toast.com/toastui/img/tui-editor-bi-white.png', desc:null}));
        return axios.post(`${this.endpoint}/board/${boardId}/article/upload/image`, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => response.data);
    }

    getMyAllArticles(userId, params){
        return axios.get(`${this.endpoint}/board/user/${userId}/articles`, {params}).then(response=>response.data)
    }

    getMyArticlesFromBoard(boardId,userId, params){
        return axios.get(`${this.endpoint}/board/${boardId}/user/${userId}/articles`, {params}).then(response=>response.data)
    }

    downloadFile(boardId, articleId, fileId) {
        // return axios.get(`${this.endpoint}/board/${boardId}/article/${articleId}/file/${fileId}`, {}).then(response=>response.data);
        return axios.get(`${this.endpoint}/board/${boardId}/article/${articleId}/file/${fileId}`, {responseType: 'arraybuffer'}).then(response=>response);
    }

    copyTo(sourceArticleId, targetBoardId) {
        return axios.post(`${this.endpoint}/board/article/${sourceArticleId}/copy/to/${targetBoardId}`, {}).then(response=>response.data);
    }

    reaction(articleId, boardId){
        return axios.post(`${this.endpoint}/board/${boardId}/article/${articleId}/reaction`, {}).then(response => response);
    }
}


export default new ArticleApi();
