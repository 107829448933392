<template>

  <!-- id : contents : (S) -->
  <div id='contents'>
    <div class="sub-visual-wrap st-community" v-if="!isComponent">
      <div class="inner-base-box">
        <h2 class="h2">{{ menuName }}</h2>
        <ol>
          <li style="background: none;">홈</li>
          <li>{{ menuName }}</li>
          <li>{{ currentTabMenu.menuName }}</li>
        </ol>
      </div>
    </div>

    <!-- class : content-wrap : (S) -->
    <section class="content-wrap">
      <div class="inner-base-box"  v-if="!isComponent">
        <div class="tab-menu-box mb-pd-none">
          <a href="javascript:;" class="btn-menu " :class="{open : this.$store.state.tapOpen === true}" @click="openTab()">탭열기버튼</a>
          <span class="t-txt">MENU</span>
        </div> 
        <div class="secPageTabBox " :class="{open : this.$store.state.tapOpen === true}">
          <div class="btn-go-back mb-only"><a href="#" @click.prevent="goback">이전페이지</a></div>
          <div class="sub-tab-menu-box st-community">
            <a href="javascript:;" v-for="(tab) in tabs" :class="{on:tab.url === '/group/schedule'}" @click="onClickTab(tab)" :key="tab.menuId">{{ tab.menuName }}</a>
          </div>
          <div class="sub-tab-mb-title">
            <a href="javascript:;" v-for="(tab) in tabs" :class="{on:tab.url === '/group/schedule'}" @click="onClickTabMobile()" :key="tab.menuId">{{ tab.menuName }} <i class="arrow"></i> </a>
          </div>
          <div class="sub-tab-list-box" :class="{on:this.tablistOpen === true}">
            <a href="javascript:;" v-for="(tab) in tabs" :class="{on:currentTabMenu.menuName==tab.menuName}" @click="onClickTab(tab)" :key="tab.menuId">{{ tab.menuName }}</a>
          </div>
        </div>
      </div>
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="inner-base-box  mb-pd-none">

          <div class="ev-calendar-wrap">
<!--            <div class="cal-tit-box">-->
<!--              <a href="" class="btn-prev">이전달</a> <span>2020년 8월</span> <a href="" class="btn-next">다음달</a>-->
<!--            </div>-->
            <div class="cal-tab-box" v-if="componentStatus !== 'detail'">
              <a href="javascript:;" @click="changeStatus('calendar')" :class="{on:componentStatus === 'calendar'}">캘린더</a>
              <a href="javascript:;" @click="changeStatus('list')" :class="{on:componentStatus === 'list'}">목록</a>
            </div>
            <div class="cal-exp-box" v-if="componentStatus !== 'detail'">※ 등록된 모임 일정이 달력에 표시됩니다.</div>
            <v-app>

            <div class="calendar-list-box" v-if="componentStatus === 'calendar'">
              <calendar
                  @callback-detail="detailCalendar"
                  @callback-change-month="(v) => searchMonth = v"
                  :is-component="isComponent"
                  :search-month="searchMonth"
                  :group-id="groupId"/>
            </div>
            <div class="calendar-list-box" v-if="componentStatus === 'list'">
              <list
                  @callback-detail="detailList"
                  @callback-change-month="(v) => searchMonth = v"
                  :search-month="searchMonth"
                  :is-component="isComponent"
                  :group-id="groupId"
              />
            </div>
            <div class="calendar-list-box" v-if="componentStatus === 'detail'">
              <detail :meeting-id="meetingId" :origin-component-status="originComponentStatus" @callback-list="goList" @click:bgDim="onClickBgDim" :isOpen="isOpen"/>
            </div>
            </v-app>

          </div>
        </div>
      </div>
      <!-- class : main-wrap (E) -->


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->
</template>

<script>
import menuApi from "@/api/MenuApi";

import calendar from '@/views/meeting/calendar'
import list from '@/views/meeting/list'
import detail from '@/views/meeting/detail'

export default {
  name: "group-schedule",
  props:{
    indexStatus:String,
    isComponent: {
      type: Boolean,
      default: false
    },
    status: String,
    isOpen:{
      type:Boolean,
      default:false
    }
  },
  components: {
    calendar,
    detail,
    list
  },
  data(){
    return{
      tabIndex: 2,
      tabs:[],
      groupId:null,

      currentTabMenu:[],
      menuName:'',
      originComponentStatus: null,
      componentStatus:'calendar',

      param: {
        searchKeyword: '',
        searchColumn: 'title',
        searchDate:[],
        searchState: null,
        page: 1,
        start: {},
        end: {}
      },

      pop1:false,
      pop2:false,
      pop3:false,

      meetingId:null,
      searchOpen:false,

      searchMonth:null,//new Date().toISOString().substr(0, 10),
      tablistOpen:false,

    }
  },
  watch:{
    indexStatus(){
      if(this.indexStatus != null) {
        this.componentStatus = this.indexStatus
        this.$emit('changeStatus')
      }
    },
    status: {
      handler(v) {
        if(v)
          this.componentStatus = v;
      },
      immediate:true
    },
    $route(){
      this.componentStatus='calendar'
      this.parentMenu = this.$route.query.upper

      menuApi.getMenu(this.parentMenu).then(data=>{
        this.menuName=data.menuName
        this.tabs = data.childMenus;    //ce분과, it분과, 디스플레이분과 etc.
        this.currentTabMenu = this.tabs[this.tabIndex-1]
      })
    },
    isOpen(e){
      if(!e){
        this.memberList=false
      }
    },
    id: {
      immediate:true,
      handler(v) {
        if(v)
          this.detailCalendar(v);
        // this.originComponentStatus = 'calendar'
        // this.componentStatus = 'detail';
        // this.meetingId = v
      },
      deep: true
    }
  },
  created() {
    this.componentStatus = this.$route.query.indexStatus?this.$route.query.indexStatus:'calendar';
    this.groupId =   (typeof  this.$route.query.groupId  !== 'undefined') ?  this.$route.query.groupId :  null;


    // if(this.id) {
    //   this.detailCalendar(this.id);
    // }
    //this.currentTab = this.$route.params.tapNo
    this.parentMenu = this.$route.query.upper
    this.articleId = this.$route.query.articleId
    if(this.articleId != null && !this.isComponent){
      this.componentStatus = 'detail';
      this.meetingId = this.articleId;
    }

    menuApi.getMenu(this.parentMenu).then(data=>{
      this.menuName=data.menuName
      this.tabs = data.childMenus;    //ce분과, it분과, 디스플레이분과 etc.
      this.currentTabMenu = this.tabs[this.tabIndex-1]
    });
  },
  methods: {
    onClickBgDim(e){
      this.isOpen = e;
      this.$emit('click:bgDim', e)
    },
    onClickSearchOpen() {
      this.searchOpen = !this.searchOpen
    },
    onClickTab(tab){
      this.param.searchKeyword= ''
      this.param.searchColumn= 'title'
      this.param.fromDate= ''
      this.param.toDate= ''
      this.currentTabMenu = tab
      this.componentStatus = 'calendar'
      this.searchOpen = false

      let url = tab.url;
      if(tab.menuType === 'TYPE_BOARD') {
        url = `/board/${tab.boardId}`;
      }

      this.$router.push(url+`?upper=${this.parentMenu}&menu=${tab.menuId}`).catch(()=>{});
    },
    changeStatus(val) {
      this.componentStatus = val;
    },
    detailCalendar(meetingId) {
      this.meetingId = meetingId;
      this.originComponentStatus = 'calendar'
      this.componentStatus = 'detail'
    },
    detailList(meetingId) {
      this.meetingId = meetingId;
      this.originComponentStatus = 'list'
      this.componentStatus = 'detail'
    },
    goList(originComponentStatus) {
      this.componentStatus = originComponentStatus;
    },
    /*탭 오픈*/
    openTab(){
      this.$store.commit('setTapOpen')
    },
    onClickTabMobile(){
      if(this.tablistOpen){
        this.tablistOpen = false;
      }else{
        this.tablistOpen = true;
      }
    },
    goback(){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
</style>