<template> 
  <div class="">
    <div class="main-wrap">
      <div class="inner-base-box">

        <div class="event-dtl-box">
          <!-- ev-body-box(S) -->
          <div class="ev-body-box">
            <div class="cate-box"><span class="mark st1" style="width:auto;padding-left: 10px;padding-right: 10px;" v-if="meeting.group.groupName != '사무국'">{{meeting.group.groupName}}</span></div>
            <div class="tit-box">
              <div class="tit ellipsis">{{ meeting.title }} <div class="txt-error" v-if="meeting.state=='STATE_CANCEL'">(취소된 모임입니다.)</div></div>
              <div class="btn-share-box">
                <a href="javascript:;" class="btn-share btnShareOpen" @click="btnShareOpen=!btnShareOpen">공유하기</a>
                <!-- btnShareOpen script 하단에 있음-->
                <!-- pop-sns-share-box(S) -->
                <div class="pop-sns-share-box" :class="{open:btnShareOpen}" >
                  <div class="re-inner-box">
                    <a href="javascript:;" class="btn-close btnShareClose" @click="btnShareOpen=false">닫기</a>
                    <!-- btnShareClose script 하단에 있음-->
                    <div class="sns-btn-box">
                      <a href="javascript:;" class="st1" style="width: 55px" @click="shareLink(1)">페이스북</a>
                      <a href="javascript:;" class="st2" style="width: 55px" @click="shareLink(2)">카카오톡</a>
                      <a href="javascript:;" class="st3" style="width: 55px" @click="shareLink(3)">밴드</a>
                    </div>
                    <div class="link-box">
                      <div class="url-txt">{{ currentURL }}</div>
                      <a href="javascript:;" class="btn-link" @click="shareLink(4)">링크복사</a>
                    </div>
                  </div>
                </div>
                <!-- pop-sns-share-box(E) -->
              </div>
            </div>
            <div class="text-box">
              <ul style="padding-left: 0px">
                <li>
                  <span class="tit ico-map ellipsis" style="width: 630px">장소</span>
                  <span class="txt">{{ meeting.place }}</span>
                </li>
                <li>
                  <span class="tit ico-place">일정</span>
                  <span class="txt">
                    {{ meeting.startDt | moment('YYYY년 MM월 DD일 dddd') }} {{ meeting.startTm ? meeting.startTm.substring(0,5) : '' }} ~
                    {{ meeting.endDt | moment('YYYY년 MM월 DD일 dddd') }} {{ meeting.endTm ? meeting.endTm.substring(0,5) : '' }}
                  </span>
                </li>
                <li>
                  <span class="tit ico-money">모임비</span>
                  <span class="txt">{{ meeting.entryFee | numeralFormat('0,000')}} 원</span>
                </li>
                <li>
                  <span class="tit ico-tel">문의</span>
                  <span class="txt">{{ meeting.askContact}}</span>
                </li>
                <li>
                  <span class="tit ico-cont">내용</span>
                  <span class="txt ellipsis-h3" style="width: 640px; height: auto; overflow:auto;padding-right: 10px;" v-html="meeting.meetingContents">
                  </span>
                </li>

              </ul>

            </div>
          </div>
          <!-- ev-body-box(E) -->
          <!-- ev-aside-box(S) -->
          <div class="ev-aside-box">
            <div class="tit-box">참석예정</div>
            <div class="count-box"><em class="dg-fnt-mont" style="cursor:pointer">{{ meeting.attendanceCount==null?0:meeting.attendanceCount}}</em> <span>명</span></div>
            <div class="btn-do-box" v-if="meeting.state!='STATE_CANCEL'">
              <!--<a href="javascript:;" class="btnMeetingAttend" v-if="meeting.state=='STATE_CANCEL'" :style="styleObject">모임 취소</a>-->
              <a href="javascript:;" class="btnMeetingAttend" v-if="isClose || meeting.state=='STATE_END'" :style="styleObject">접수 마감</a>
              <a href="javascript:;" class="btnMeetingAttend" v-else-if="!joined && meeting.state=='STATE_RECEIPT'" @click="onClickOpenPopup(true)">모임 참석</a>
              <a href="javascript:;" class="btnMeetingAttend" v-else style="line-height: 27px;height: 70px;padding-top: 10px;padding-bottom: 10px;" :style="styleObject" @click="onClickJoinCancel">참석 취소 <br>(입금 {{deposited?'완료':'대기'}})</a>
            </div><!-- btnMeetingAttend script 하단에 있음-->
            <div class="map-info-box" v-if="meeting.lon != null" >
              <div class="tit-box">지도보기</div>
              <div class="map-box">
                <!-- 지도 자리 275px * 275px -->
                <img @click="goToMap(meeting)" :src="`https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors?w=275&h=275&center=${ meeting.lon },${ meeting.lat }&markers=markers=type:d|size:mid|pos:${ meeting.lon }%20${ meeting.lat }&level=16&X-NCP-APIGW-API-KEY-ID=${$store.state.config.ext.naver.staticmap.id}`"
                style="cursor:pointer;">
              </div>
              <div class="address-box">{{meeting.address}} {{ meeting.addressDetail }} </div>
            </div>
          </div>
          <!-- ev-aside-box(E) -->
        </div>
        <div class="board-btn-box al-left wd150"><a href="javascript:;" @click="goToList" class="st2" style="color: white;margin-right:0.5rem">목록보기</a> 
        <a href="javascript:;" @click="updateSchedule()" class="st2" style="color: white;margin-right:0.5rem" v-if="this.$store.state.user.userid == this.meeting.createdBy">수정하기</a>
        <a href="javascript:;" @click="joinmemberClick()" class="st2" style="color: white;margin-right:0.5rem" v-if="this.$store.state.user.userid == this.meeting.createdBy">참석 인원 목록</a>
        </div>
        

      </div>
    </div>
    <!-- class : main-wrap (E) -->

    <div class="dg-pop-layer pop-wd-mid st-meeting-attend" id="popMeetingAttendBox" :class="{on:popupOpen}">
      <div class="dg-pop-hd-st2-box st-line">
        <div class="pop-h1">모임참석 신청</div>
        <div class="btn-close-box"><a href="javascript:;" class="btn-close dg-pop-close" @click="onClickOpenPopup(false)">닫기</a></div>
      </div>
      <div class="pop-cont-box ">
          <div class="pop-meeting-form">
            <div class="row-box">
              <span class="tit-box">동반 가족수</span>
              <span class="input-box">
                      <select v-model="meetingHasUser.familyCount" class="input-st1 st-selectbox wd150" :disabled="!meeting.family?true:false">
                        <option v-if="!meeting.family" value="null">불가</option>
                        <option value="0">선택</option>
                        <option :value="index" v-for="index in 10" :key="index">{{ index }}명</option>
                      </select>
                  </span>
            </div>
            <div class="row-box">
              <span class="tit-box">모임비</span><span class="input-box">{{ meeting.entryFee | numeralFormat('0,000')}} 원</span>
            </div>
          </div>
        <div class="board-btn-box wd150 al-center mb-none">
          <a href="javascript:;" style="color: white!important;" class="st2 dg-pop-close" @click="onClickOpenPopup(false)">취소</a>
          <a href="javascript:;" style="color: white!important;" @click="join" class="">확인</a></div>
      </div>
    </div>

    <transition name="fade">
    <JoinmemberPopup v-if="joinmember" :meetingId="this.meetingId" @close="joinmemberClick" ></JoinmemberPopup>
    </transition> 
  </div>
</template>
<script src="https://developers.kakao.com/sdk/js/kakao.min.js"></script>
<script>
import menuApi from "@/api/MenuApi";
import meetingApi from '@/api/MeetingApi'
import meetingUserApi from '@/api/MeetingUserApi'
import JoinmemberPopup from "@/views/include/joinmember_popup";
// import groupApi from "@/api/GroupApi";


export default {
  name: "CalendarDetail",
  props: {
    meetingId: Number,
    originComponentStatus: String,
    isOpen:{
      type:Boolean,
      default:false
    }
  },
  components:{JoinmemberPopup},
  data(){
    return{
      joinmember:false,
      tabIndex: 2,
      tabs:[],
      currentTabMenu:[],
      meeting:{
        group:{
        }
      },
      meetingHasUser:{
        familyCount:0,
        attendance:1
      },
      popupOpen:false,
      joined:false,
      beforeJoined:false,
      deposited:false,
      isClose:false,

      styleObject:{
        'background-color':'#666666'
      },
      btnShareOpen:false,
      currentURL: document.location.href.split('?')[0]+'?articleId='+this.meetingId+'&upper='+this.$route.query.upper+'&menu='+this.$route.query.menu

      ,feedSettings:{
        link:{
          mobileWebUrl:"https://webruden.tistory.com"
        }
      }
    }
  },
  mounted() {
    Kakao.init('b207d06c170136f84a9b0db9ba732fa5');
  },
  watch:{
    isOpen(e){
      if(!e){
        this.popupOpen=false
      }
    }
  },
  created() {
    this.parentMenu = this.$route.query.upper
    menuApi.getMenu(this.parentMenu).then(data=>{
      this.tabs = data.childMenus;    //ce분과, it분과, 디스플레이분과 etc.
      this.currentTabMenu = this.tabs[this.tabIndex-1]
    })

    meetingApi.get(this.meetingId).then(data => {
      this.meeting = data;
      if(!this.meeting.family){
        this.meetingHasUser.familyCount=null;
      }

      let moment = require('moment')
      let now = moment().format('YYYY-MM-DD HH:mm:ss')
      let end = '';
      if(data.endTm == null){
        end = moment(data.endDt).add(1,'days').format('YYYY-MM-DD')
      }else{
        end = moment(data.endDt+'T'+data.endTm).format('YYYY-MM-DD HH:mm:ss')
      }

      if(now > end){
        this.isClose = true;
      }
      if(!this.meeting.group.parentId) {
        this.meeting.group.parentId = this.meeting.groupId;
      }

      this.styleObject={
        'background-color':'#666666',
        'cursor':(this.isClose || this.meeting.state=='STATE_END' || this.meeting.state=='STATE_CANCEL')?'default':'pointer'
      }

      this.isJoined();
    });
  },
  methods:{

    joinmemberClick(){
      this.joinmember=!this.joinmember;
      this.$emit('click:bgDim', this.joinmember)
      
    },
    updateSchedule(){
        // this.$router.push({path : '/group/schedule/registration/',query:{upper:this.parentMenu,menu: this.currentTabMenu.menuId} })
        this.$router.push({path : `/group/schedule/registration/${this.meeting.meetingId}`,query:{upper:this.parentMenu,menu: this.currentTabMenu.menuId} })
    },
    onClickTab(tab) {
      this.boardId = tab.boardId
      this.currentTabMenu = tab
      this.searchOpen = false
      this.boardType = 'TYPE_GENERAL'
      this.boardName = this.currentTabMenu.childMenus[0].menuName


      let url = tab.url;
      if(tab.menuType === 'TYPE_BOARD') {
        url = `/board/${tab.boardId}`;
      }

      this.$router.push(url+`?upper=${this.parentMenu}`).catch(()=>{});
    },

    goToList(){
      this.$emit('callback-list', this.originComponentStatus)
      if(this.originComponentStatus == null){
        this.$emit('callback-list', 'calendar')
      }
      // this.$router.push(`/group/schedule?upper=${this.parentMenu}`)
    },
    onClickOpenPopup(e){
      if(!this.$store.state.user?.userid??false) {
        return alert('로그인이 필요한 서비스입니다.')
      }else if(this.joined) {
        return alert('이미 참석 신청한 모임입니다.');
      }else if(this.beforeJoined) {
        return alert('참석 신청을 취소한 내역이 있습니다. \n참석 재신청은 관리자에게 문의해주세요.');
      }
      this.popupOpen=e;
      this.$emit('click:bgDim', this.popupOpen)
    },
    join() {
      meetingUserApi.join(this.meeting.meetingId, this.meetingHasUser).then(() => {
        this.joined = true;
        this.popupOpen = false;
        this.$emit('click:bgDim', this.popupOpen);
        alert('참석 신청되었습니다.')

        meetingApi.get(this.meetingId).then(data => {
          this.meeting = data;

          this.styleObject={
            'background-color':'#666666',
            'cursor':(this.isClose || this.meeting.state=='STATE_END' || this.meeting.state=='STATE_CANCEL')?'default':'pointer'
          }
        })
      });
    },
    onClickJoinCancel(){
      //axios.put(`${this.endpoint}/group/meeting/${meetingId}/change/attendance/${userId}`, {}).then(response=>response.data);
      if(confirm('참석 신청을 취소하시겠습니까?')) {
        meetingUserApi.changeAttendance(this.meetingId, this.$store.state.user.userid).then(() => {
          this.joined = false;
          alert('모임 참석이 취소되었습니다.')
          meetingApi.get(this.meetingId).then(data => {
            this.meeting = data;
          })
          this.isJoined();
        });
      }
    },
    goToMap(meeting){
      //alert(`https://map.kakao.com/link/map/${meeting.place},${meeting.lat},${meeting.lon}`)
      window.open(`https://map.kakao.com/?q=${meeting.address}`)
    },
    shareLink(dv){
      let url = '';
      let encodeCurrentURL = encodeURIComponent(this.currentURL);

      if(dv==1){ //fb
        url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeCurrentURL;
        window.open(url, '', 'width=800, height=800');
      }else if(dv==2){ //kakao
        Kakao.Link.sendDefault({
          objectType: 'feed',
          content: {
            title: "e-CLUB 전자사랑모임",
            description: this.meeting.title,
            imageUrl: '',
            link: {
              mobileWebUrl: this.currentURL,
              webUrl: this.currentURL
            }
          },
          buttons: [{
            title: '상세보기',
            link: {
              webUrl: this.currentURL
            }
          }]
        });
      }else if(dv==3){ //band
        url = 'https://band.us/plugin/share?body=' + encodeCurrentURL + '%0A' + encodeURIComponent(this.meeting.title) +'%0A&route=tistory.com'
        window.open(url, '', 'width=800, height=800');

      }else if(dv==4){ //copy
        let dumTA = document.createElement("input");
        document.body.appendChild(dumTA);
        dumTA.value = this.currentURL;
        dumTA.select();
        document.execCommand("copy");
        dumTA.remove();
        alert('링크가 복사되었습니다.')
      }
    },
    isJoined(){
      meetingUserApi.joinedUser(this.meetingId).then(user => {
        if(user) {//user 존재 == group_meeting_has_users 테이블에 존재
          if(user.attendance){
            this.joined = true;
          }else if (!user.attendance) {//is_attendance=1 == 신청, is_attendance=0 == 신청 후 취소
            this.beforeJoined = true;
          }

          if(user.deposited){
            this.deposited = true;
          }
        }
      }).catch(e => {
        console.log(e.response);
      })
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar{
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb{
  border-radius: 8px;
  background: #6e6e6e;
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.5)
}
::-webkit-scrollbar-track{
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.5)
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>