<template>
  <section>
    <div class="board-search-wrap" style="margin-bottom: 0">
      <div class="tit-box" v-if="!isComponent">
        <v-btn-toggle
            v-model="searchParentGroup"
            dense
            group
        >
          <v-btn :value="t.groupId" v-for="(t) in parentGroups" :key="t.value">
            <v-icon small :color="`${t.groupId === 3 ? '#526de1' : t.groupId === 1 ? '#ffab3d' : '#ff6969'}`">mdi-checkbox-blank-circle</v-icon>
            {{t.groupName}}
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="tit-box" v-else>
        &nbsp;
      </div>

      <div class="search-box">
        <form @submit.prevent="">
          <input type="text" value="" v-model="param.searchKeyword" placeholder="제목,내용,장소를 입력해주세요." class="input-st1 wd250 mr10 mb80" style="margin-left: 10px">
          <button @click="search" class="btn-s mb20">검색</button>
        </form>
      </div>
    </div>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar
              flat
          >
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ param.searchStartDt | moment('M월 YYYY') }}
            </v-toolbar-title>
            <v-spacer></v-spacer>

          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
            <div class="tb-01 st-list">
              <table>
                <colgroup><col style="width:110px"><col style="width:170px"><col style="width:210px"><col style="width:140px"><col style="width:*"></colgroup>
                <thead>
                <tr>
                  <th>일자</th>
                  <th>구분</th>
                  <th>제목</th>
                  <th>장소</th>
                  <th>내용</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="s in schedules" :key="s.meetingId" :class="$moment().format('YYYY-MM-DD') === s.startDt ? 'today' : $moment(s.startDt).format('ddd') === '일' ? 'sunday' : ''">
                  <td class="al-center">{{ s.startDt | moment('MM.DD(ddd)') }}</td>
                  <td class="al-left"><span :class="`mark-cate st${!s.group.parent && s.group.groupId === 3 ? 1 : s.group.parent.groupId+1 }`"></span>{{ s.group.parent ? `${s.group.parent.groupName} > ` : '' }} {{ s.group.groupName }}</td>
                  <td class="al-left" style="cursor: pointer" @click="goToDetail(s)" >{{ s.title }}</td>
                  <td class="al-left">{{ s.place }}</td>
                  <td class="al-left ellipsis_inline" style="cursor: pointer; width:100%; height:46px" @click="goToDetail(s)" >{{ s.strippedContent }}</td>
                </tr>
                </tbody>
              </table>
            </div>
        </v-sheet>
      </v-col>
    </v-row>
  </section>


</template>

<script>
import menuApi from "@/api/MenuApi";
import groupApi from '@/api/GroupApi'
import moment from "moment";
import meetingApi from "@/api/MeetingApi";

export default {
  name: "GroupScheduleList",
  props: {
    searchMonth: String,
    isComponent: {
      type: Boolean,
      default: false
    },
    groupId:Number
  },
  data(){
    return{
      tabIndex: 2,
      tabs:[],

      currentTabMenu:[],
      boardId:'',
      boardName:'',
      board:{},
      menuName:'',

      param: {
        searchKeyword: '',
        searchColumn: 'title',
        searchDate:[],
        searchState: null,
        page: 1,
        start: {},
        end: {}
      },

      pop1:false,
      pop2:false,
      pop3:false,

      schedules: [],
      totalItems: 0,
      dialogVisible: false,
      waitingSearch: false,
      options:{},

      searchParentGroup: null,
      parentGroups: [],
    }
  },
  watch:{
    $route(){
      this.tabIndex = this.$route.params.id
      this.parentMenu = this.$route.query.upper

      menuApi.getMenu(this.parentMenu).then(data=>{
        this.menuName=data.menuName
        this.tabs = data.childMenus;    //ce분과, it분과, 디스플레이분과 etc.
        this.currentTabMenu = this.tabs[this.tabIndex-1]

        this.onClickSearch();
      })

    },
    isOpen(e){
      if(!e){
        this.memberList=false
      }
    },
    boardId(){
      this.onClickSearch();
    },
    searchParentGroup: {
      handler(nv, ov) {
        if(nv !== null && nv === ov)
          return;
        if(nv !== null && nv === this.param.searchParentGroup)
          return;

        this.param.searchParentGroup = null;
        if(nv === null || ov === 3) {
          this.param.searchGroup = null;
        }
        if(nv === 3) {
          this.param.searchGroup = 3;
        } else {
          this.param.searchParentGroup = nv;
        }

        this.search();
      },
      deep: true
    }
  },
  created() {
    window.scrollTo(0,0);
    //this.currentTab = this.$route.params.tapNo
    this.parentMenu = this.$route.query.upper
    menuApi.getMenu(this.parentMenu).then(data=>{
      this.menuName=data.menuName
      this.tabs = data.childMenus;    //ce분과, it분과, 디스플레이분과 etc.
      this.currentTabMenu = this.tabs[this.tabIndex-1]
    })


    groupApi.flatList().then(data => {
      // console.log(data);
      this.parentGroups = data.filter(d => d.activated);
    });

    // console.log(this.searchMonth)

    this.param.searchStartDt = (this.searchMonth??moment().format("YYYY-MM"))+"-01"
    this.param.searchEndDt = moment(this.searchMonth??moment().format("YYYY-MM")+"-01").endOf('month').format("YYYY-MM-DD")

    this.search();
  },
  methods: {
    prev () {
      this.param.searchStartDt = this.$moment(this.param.searchStartDt).add('month', -1).format("YYYY-MM")+"-01";
      this.param.searchEndDt = this.$moment(this.param.searchStartDt).endOf('month').format("YYYY-MM-DD");
      // console.log(this.param)
      this.$emit('callback-change-month', moment(this.param.searchStartDt).format("YYYY-MM"));
      this.search();
    },
    next () {
      this.param.searchStartDt = this.$moment(this.param.searchStartDt).add('month', 1).format("YYYY-MM")+"-01";
      this.param.searchEndDt = this.$moment(this.param.searchStartDt).endOf('month').format("YYYY-MM-DD");
      // console.log(this.param)
      this.$emit('callback-change-month', moment(this.param.searchStartDt).format("YYYY-MM"));
      this.search();
    },
    onClickTab(tab){
      this.param.searchKeyword= '',
      this.param.searchColumn= 'title',
      this.param.fromDate= '',
      this.param.toDate= ''

      this.boardId = tab.boardId
      this.currentTabMenu = tab
      this.searchOpen = false
      this.boardType = 'TYPE_GENERAL'
      this.boardName = this.currentTabMenu.childMenus[0].menuName

      let url = tab.url;
      if(tab.menuType === 'TYPE_BOARD') {
        url = `/board/${tab.boardId}`;
      }

      this.$router.push(url+`?upper=${this.parentMenu}`).catch(()=>{});
    },
    goToDetail({meetingId}){
      this.$emit('callback-detail', meetingId);
      // this.$router.push(`/community/calendarDetail?upper=${this.parentMenu}`)
    },
    search() {
      Object.assign(this.param, {
          page: 1,
          size: -1,
          searchGroup : this.groupId??null
        });
      const colors =  ['deep-purple', '#ffab3d', '#ff6969', '#526de1', 'blue', 'green', 'orange', 'cyan', 'grey darken-1', 'indigo']

      meetingApi.list(this.param).then(data => {

        this.totalItems = data.totalElements;
        this.schedules = data.content.map(m => {
          return Object.assign(m, {
            name: m.title,
            start: moment(`${m.startDt} ${m?.startTm?? '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss').toDate(),// ? m.startDt + (m.startTm ? ' ' + m.startTm.substr(0, 5) : '') : null,
            end: moment(`${m.endDt} ${m?.endTm?? '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss').toDate(), // ? m.endDt + (m.endTm ? ' ' + m.endTm.substr(0, 5) : '') : null,
            color: colors[m.group?.parent?.groupId??m.groupId],
            // color: colors[this.rnd(0, colors.length - 1)],
            timed: m.startTm && m.endTm,
            strippedContent: m.meetingContents?.replace(/<\/?[^>]+>/ig, "")??''
          });
        }).sort((a,b) => {
          return a.start - b.start
        });//.filter(m => m.start !== null && m.end !== null);

        // console.log(this.schedules);

      });
    },
  }
}
</script>

<style scoped>
</style>