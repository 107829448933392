<template>
<section>
    <div class="board-search-wrap" style="margin-bottom: 0">
      <div class="tit-box" v-if="!isComponent">
          <v-btn-toggle
              v-model="searchParentGroup"
              dense
              group
          >
            <v-btn :value="t.groupId" v-for="(t) in parentGroups" :key="t.value">
              <v-icon small :color="`${t.groupId === 3 ? '#526de1' : t.groupId === 1 ? '#ffab3d' : '#ff6969'}`">mdi-checkbox-blank-circle</v-icon>
              {{t.groupName}}
            </v-btn>
          </v-btn-toggle>

<!--                <div class="footnote-box">-->
<!--                  <span class="st1">전체</span>-->
<!--                  <span class="st2">분과</span>-->
<!--                  <span class="st3">동호회</span>-->
<!--                </div>-->
      </div>
      <div class="tit-box" v-else>
        &nbsp;
      </div>

      <div class="search-box">
        <form @submit.prevent="" class="mb-search-one">
          <input type="text" value="" v-model="param.searchKeyword" placeholder="제목,내용,장소를 입력해주세요." class="input-st1 wd250 mr10 mb80" style="margin-left: 10px">
          <button @click="search" class="btn-s mb20" style="margin-right:0.5rem">검색</button> 
        </form>
      </div>
    </div>
    <v-row class="fill-height calendar-top-box">
      <v-col>
        <v-sheet height="64">
          <v-toolbar
              flat
          >
            <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
            >
              Today
            </v-btn>
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
                class="pc-only"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
                class="mb-only"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn class="vBtn vIconResize mgr pc-only" @click="scRegistration()" color="#526de1" dark style="margin-right: 5px">
              <v-icon left>mdi-lead-pencil</v-icon>
              일정등록
            </v-btn>
            <span class="calendar-btn-list-box">
              <v-btn class="vBtn vIconResize mgr" @click="calOptions.type = 'month'" color="#526de1" dark style="margin-right: 5px">
                <v-icon left>mdi mdi-calendar-month</v-icon>
                월
              </v-btn>
              <v-btn class="vBtn vIconResize mgr" @click="calOptions.type = 'week'" color="#526de1" dark style="margin-right: 5px">
                <v-icon left>mdi mdi-calendar-week</v-icon>
                주
              </v-btn>
              <v-btn class="vBtn vIconResize mgr" @click="calOptions.type = 'day'" color="#526de1" dark style="margin-right: 5px">
                <v-icon left>mdi mdi-calendar-today</v-icon>
                일
              </v-btn>

<!--                    <v-btn class="vBtn vIconResize mgr" @click="customCalendarType" color="primary" dark>-->
<!--                      <v-icon left>mdi mdi-calendar-minus</v-icon>-->
<!--                      3일-->
<!--                    </v-btn>-->
            <!--                    <v-btn class="vBtn vIconResize mgr" @click="calOptions.type = '4day'" color="primary" dark>-->
            <!--                      <v-icon left>mdi mdi-calendar-minus</v-icon>-->
            <!--                      4일-->
            <!--                    </v-btn>-->
              <v-btn class="vBtn vIconResize mgr" @click="calOptions.type = 'category'" color="#526de1" dark v-if="!isComponent">
                <v-icon left>mdi mdi-group</v-icon>
                그룹
              </v-btn>


            <!--                <v-btn class="vBtn vIconResize mgr" @click="$router.push({path: 'meeting/form'})" color="#666" dark>-->
            <!--                  <v-icon left>mdi mdi-playlist-plus</v-icon>-->
            <!--                  등록-->
            <!--                </v-btn>-->

            </span>
            

          </v-toolbar>
        </v-sheet>
        <v-sheet height="630">
          <v-calendar
              ref="calendar"
              v-model="calOptions.focus"
              color="#e0e0e0"
              :events="schedules"
              :event-color="getEventColor"
              :event-more="calOptions.more"
              :type="calOptions.type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="changeCalendar"
              category-show-all
              :categories="calOptions.categories"
          >
            <template v-slot:day-label="{past, date, day, present, weekday }">
              <template v-if="true">
                <div class="v-calendar-weekly__day-label d-flex">
                  <v-btn
                      small rounded fab depressed
                      class="has-bg transparent"
                      :color="present ? 'primary' : 'white'"
                      :dark="present"
                      @click="viewDayGo(date)"
                  >
                    <span class="v-btn__content" :style="present ? 'color:primary' : weekday === 0 || holidays.filter(h => h.formatDate === date).length > 0 ? 'color:red' : weekday === 6 ? 'color:blue' : 'color:black'">{{ day }}</span>
                  </v-btn>

                  <div class="v-event v-event-start v-event-end pl-1" style="padding-top:10px" v-if="holidays.filter(h => h.formatDate === date).length > 0">{{holidays.filter(h => h.formatDate === date)[0].dateName}}</div>

                </div>
                <!--                    <button type="button" class="v-btn v-btn--depressed v-btn--fab v-btn--rounded theme--light v-size--default grey"><span class="v-btn__content">55 추석 연휴</span></button>-->
                <!--                    <button type="button" class="v-btn v-btn--depressed v-btn--fab v-btn--round theme--light v-size--small transparent"><span class="v-btn__content">a</span></button>-->
                <!--                    {{ present }}-->
                <!--                    {{ past }}-->
                <!--                    {{ date }}-->
              </template>
            </template>
          </v-calendar>
        </v-sheet>
        <div class="mb-only board-btn-box mt10">
          <a href="#" @click="scRegistration()">일정등록</a>
        </div>
      </v-col>
    </v-row>
</section>
</template>

<script>
import menuApi from "@/api/MenuApi";
import meetingApi from '@/api/MeetingApi'
import groupApi from '@/api/GroupApi'
import utilApi from "@/api/UtilApi";


import moment from 'moment';

export default {
  name: "community_event_calendar",
  props: {
    searchMonth: String,
    isComponent: {
      type: Boolean,
      default: false
    },
    groupId:String
  },
  data(){
    return{
      tabIndex: 2,
      tabs:[],

      currentTabMenu:[],
      menuName:'',
      componentStatus:'calendar',

      param: {
        searchKeyword: '',
        searchDate:[],
        searchState: null,
        page: 1,
        start: {},
        end: {}
      },

      pop1:false,
      pop2:false,
      pop3:false,
      focus:'',
      schedules: [],
      totalItems: 0,
      dialogVisible: false,
      waitingSearch: false,
      options:{},
      calOptions:{
        focus: '',
        type: 'month',
        more: true,
        categories: ['분과', '동호회', '사무국']
      },
      events: [],
      parentGroups: [],
      searchParentGroup: null,
      init: true,
      holidays: []
    }
  },
  watch: {
    searchParentGroup: {
      handler(nv, ov) {
        if(nv !== null && nv === ov)
          return;
        if(nv !== null && nv === this.param.searchParentGroup)
          return;

        this.param.searchParentGroup = null;
        if(nv === null || ov === 3) {
          this.param.searchGroup = null;
        }
        if(nv === 3) {
          this.param.searchGroup = 3;
        } else {
          this.param.searchParentGroup = nv;
        }

        this.search();
      },
      deep: true
    }
  },
  mounted() {
    const toMonth = this.calOptions.focus && this.calOptions.focus !== '' ? this.$moment(this.calOptions.focus).format('YYYY-MM') : this.$moment().format('YYYY-MM');
    this.getHolidays(toMonth.split('-')[0], toMonth.split('-')[1]);
  },
  created() {
    window.scrollTo(0,0);
    //this.currentTab = this.$route.params.tapNo
    this.parentMenu = this.$route.query.upper
    menuApi.getMenu(this.parentMenu).then(data=>{
      this.menuName=data.menuName
      this.tabs = data.childMenus;    //ce분과, it분과, 디스플레이분과 etc.
      this.currentTabMenu = this.tabs[this.tabIndex-1]
    });
    groupApi.flatList().then(data => {
      // console.log(data);
      this.parentGroups = data.filter(d => d.activated);
    });
  },
  methods: {
    scRegistration(){
      this.$router.push({path : '/group/schedule/registration/',query:{upper:this.parentMenu,menu: this.currentTabMenu.menuId, c: 'write'} })
    },
    getHolidays(year, month) {
      utilApi.holidays(year, month).then(data => {
        this.holidays = data;
        // console.log(this.holidays);
      })
    },
    changeStatus(val) {
      this.componentStatus = val
    },
    async changeCalendar({ start, end }) {

      const startMonth = this.$moment(start.date).format('YYYY-MM');
      await this.getHolidays(startMonth.split('-')[0], startMonth.split('-')[1]);

      if(!this.searchMonth)
        this.searchMonth = moment(start.date).format("YYYY-MM")

      if(!this.searchMonth || !this.init) {
        this.param.searchStartDt = moment(start.date).add(-7, 'days').format('YYYY-MM-DD')
        this.param.searchEndDt = moment(end.date).add(7, 'days').format('YYYY-MM-DD')
        this.$emit('callback-change-month', moment(start.date).format("YYYY-MM"));
      } else {
        this.param.searchStartDt = moment(this.searchMonth+"-01").add(-7, 'days').format("YYYY-MM-DD");
        this.param.searchEndDt = moment(this.param.searchStartDt).add(7, 'days').endOf('month').format("YYYY-MM-DD");
        this.calOptions.focus = this.searchMonth+"-01";
        this.init = false;
      }

      this.search();
    },
    search() {
      let params = {...this.param, ...{
          page: 1,
          size: -1,
          searchGroup : this.groupId??null
        }};
      const colors =  ['deep-purple', '#ffab3d', '#ff6969', '#526de1', 'blue', 'green', 'orange', 'cyan', 'grey darken-1', 'indigo']

      meetingApi.list(params).then(data => {

        this.totalItems = data.totalElements;
        this.schedules = data.content.map(m => {
          return Object.assign(m, {
            name: m.title,
            start: moment(`${m.startDt} ${m?.startTm?? '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss').toDate(),// ? m.startDt + (m.startTm ? ' ' + m.startTm.substr(0, 5) : '') : null,
            end: moment(`${m.endDt} ${m?.endTm?? '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss').toDate(), // ? m.endDt + (m.endTm ? ' ' + m.endTm.substr(0, 5) : '') : null,
            color: colors[m.group?.parent?.groupId??m.groupId],
            // color: colors[this.rnd(0, colors.length - 1)],
            timed: m.startTm && m.endTm,
            category:this.calOptions.categories[(m.group?.parent?.groupId??m.groupId)-1]
          });
        });//.filter(m => m.start !== null && m.end !== null);
        // this.$refs.calendar.scrollToTime('14:00')
        this.$refs.calendar.scrollToTime({hour: 13, minute: 0});
        this.$store.commit('setParams', params);
        // console.log(this.schedules);

      });
    },
    setToday () {
      this.calOptions.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    getEventColor (event) {
      return event.color
    },
    // rnd (a, b) {
    //   return Math.floor((b - a + 1) * Math.random()) + a
    // },
    viewDay ({ date }) {
      this.calOptions.focus = date
      this.calOptions.type = 'day'
    },
    viewDayGo(value){
      this.calOptions.focus = value
      this.calOptions.type = 'day'
    },
    showEvent ({ event }) {
      this.$emit('callback-detail', event.meetingId);
      // this.$router.push({path:`/group/schedule/detail/${event.meetingId}`});
    },
  }
}
</script>

<style scoped>

</style>