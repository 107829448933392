<template>
    <div class="dg-pop-layer on pop-wd-mid st-meeting-attend" id="popupAccount">
      <div class="dg-pop-hd-st2-box st-line mb-none">
        <div class="pop-h1">계좌정보 확인</div>
        <div class="btn-close-box"><a href="javascript:;" class="btn-close dg-pop-close" @click="closePopup">닫기</a></div>
      </div>
      <div class="pop-cont-box ">

        <div class="tb-01 st-w mb20 pc-only">
          <table>
            <colgroup><col width="150px"></colgroup>
            <tbody>
            <tr >
              <th class="al-mid">은행명</th>
              <td>{{ bank }} ({{ name }})</td>
            </tr>
            <tr >
              <th class="al-mid">계좌번호</th>
              <td>{{ account }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="mb-only">
          <div class="bank-info-box">
            <div class="inner-box">
              <div class="name">{{ bank }} ({{ name }})</div>
              <div class="bank-number">{{ account }}</div>
            </div>
          </div>
        </div>
        <div class="tb-exp-box">* 계좌번호와 계좌주명을 확인해주세요.</div>
      </div>
    </div>
</template>

<script>
export default {
  name: "AccountPopup",
  data(){
    return {

    }
  },
  props:{
    bank:{
      type:String
    },
    name:{
      type:String
    },
    account:{
      type:Number
    }
  },
  methods:{
    closePopup(){
      this.$emit('click:bgDimClose',false)
    },
  }
}
</script>

<style scoped>
.bank-info-box{padding:1rem}
.bank-info-box .inner-box{background: #f5f5f5;border-radius: 5px;padding:1rem;display: flex;flex-direction: column;justify-content: center;align-items: center;}
.tb-exp-box{padding:0 1rem;font-size: .8rem;}
</style>