import axios from 'axios';
import {clarify} from "@/utils/clear";

class CommentApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
    }


    list(boardId, articleId, body) {
        const params = new URLSearchParams(clarify(body));
        //return axios.get(`${this.endpoint}/board/${boardId}/articles/${articleId}/comments`, {params}).then(response=>response.data);
        return axios.get(`${this.endpoint}/board/${boardId}/articles/${articleId}/TreeComment`, {params}).then(response=>response.data);
    }

    get(boardId, articleId, commentId) {
        return axios.get(`${this.endpoint}/board/${boardId}/articles/${articleId}/comments/${commentId}`, {}).then(response=>response.data);
    }

    total(boardId, articleId) {
        return axios.get(`${this.endpoint}/board/${boardId}/articles/${articleId}/totalComment`).then(response=>response.data);
    }

    save(boardId, comment) {
        return axios.post(`${this.endpoint}/board/${boardId}/article/comment/save`, comment).then(response=>response.data);
    }

    delete(boardId, commentId) {
        return axios.delete(`${this.endpoint}/board/${boardId}/delete/comment/${commentId}`, {}).then(response=>response.data);
    }

}


export default new CommentApi();
