<template>

    <div class="joinTable">
        <v-card>
            <v-card-title>
             
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="회원검색"
                single-line
                hide-details
            ></v-text-field>
            </v-card-title>
            <v-data-table
            class="jTable"
            :headers="headers"
            :items="joinmembers"
            :search="search"
            :options.sync="options"
            sortable="false"
            ></v-data-table>
            <div class="closeBnt" ><v-btn @click="close" class="closeBtn">확인</v-btn></div>
        </v-card>
    </div>

</template>

<script>

import meetingUserApi from '@/api/MeetingUserApi'

export default {
    name : "joinmemberpopup",
    props:['meetingId'],
    data(){
        return{
            search: '',
            headers:[
                {text : '아이디', value: 'userId'},
                {text : '이름', value: 'name'},
                {text : '이메일', value: 'userId'},
                {text : '휴대폰번호', value: 'mobileNo'},
                {text : '전자부서', value: 'retirementDepartment'},
                {text : '전자직위', value: 'retirementPosition'},
            ],
            joinmembers:[],
            options:{},
            param:{
                page:1,
                size:10
            },
        }
    },
    watch:{
        'options':{
            handler(value){
                this.page = value.page;
                this.size = value.itemsPerPage;
                this.getJoinMemberList();
               },
            },
    },
    methods:{
        close(){
            this.$emit('close',false);
        },
        getJoinMemberList(){
            meetingUserApi.list(this.meetingId).then(data=>{
            this.joinmembers = data;
        })
        }
    },
    created(){
      this.getJoinMemberList();
    }

    
}

</script>

<style scoped>
.closeBnt{
    text-align: center;
    padding-bottom: 1rem;
}
.closeBtn{
    font-weight: 500;
    color: aliceblue;
    background-color: gray !important;
}
.joinTable{
    position: fixed;
    width:70%;
    background-color: white;
    top:13rem;
    left:16rem;
    z-index: 101;
}
.jTable{
    padding:2rem;
}



</style>
